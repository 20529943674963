import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './App.css';

import ResponsiveAppBar from './components/common/Appbar';
import Dashboard from './components/dashboard/DashboardPage';
import Signup from './components/auth/SignupPage';
import ContactPage from './components/contact/ContactPage';
import FoodItemsList from './components/food-item/food-inventory/FoodItemsList'
import StepByStepDeletePage from './components/delete-profile/DeletePage';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/login" element={<Signup />} />
          <Route path="/food-items" element={<FoodItemsList />} />
          <Route
            path="/"
            element={
              // <PrivateRoute>
                <Dashboard />
              // </PrivateRoute>
            }
          />
          <Route
            path="/delete-user-data"
            element={
              // <PrivateRoute>
                <StepByStepDeletePage />
              // </PrivateRoute>
            }
          />
          {/* <Route path="/" element={<Home />} /> */}
        </Routes>
      </div>
    </Router>

    // <div className="App">
    //   <ResponsiveAppBar></ResponsiveAppBar>
    //   <Dashboard></Dashboard>
    //   {/* <Signup></Signup> */}
    // </div>
  );
}

export default App;
