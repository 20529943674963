import React from 'react';
import './style.css'; // Include the CSS file for styling
import img1 from "./../../static/delete-user-data/1.jpeg"
import img2 from "./../../static/delete-user-data/2.jpeg"
import img3 from "./../../static/delete-user-data/3.jpeg"
import img4 from "./../../static/delete-user-data/4.jpeg"
import img5 from "./../../static/delete-user-data/5.jpeg"
import img6 from "./../../static/delete-user-data/6.jpeg"


const steps = [
  { id: 1, image: img1, title: 'Step 1: Click on the profile icon' },
  { id: 2, image: img2, title: 'Step 2: Now press the "User Action" button' },
  { id: 3, image: img3, title: 'Step 3: Now click the "Delete Profile" button' },
  { id: 4, image: img4, title: 'Step 4: Now type valid reason in text-box and click "Delete" button' },
  { id: 5, image: img5, title: 'Step 5: Confirm you want to delete by pressing the "Delete" button' },
  { id: 6, image: img6, title: 'Step 6: All data including your profile, order-history will be deleted instantly' },
];

const StepByStepDeletePage = () => {
  return (
    <div className="step-container">
      {steps.map((step) => (
        <div key={step.id} className="step">
          <h2 className="step-title">{step.title}</h2>
          <img src={step.image} alt={step.title} className="step-image" />
          <hr></hr>
        </div>
      ))}
    </div>
  );
};

export default StepByStepDeletePage;
