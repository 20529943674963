import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './foodItemSlice';

const foodItemStore = configureStore({
    reducer: {
        data: dataReducer,
    },
});

export default foodItemStore;
