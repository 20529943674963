import React from "react";
import ResponsiveAppBar from "../../common/Appbar";
// import YoutubeEmbed from "./../common/youtube/Youtube";
import RecipeReviewCard from "../../common/Card";
import orderFoodImage from "./../../../static/images/order_food.jfif";
import orderGroceryImage from "./../../../static/images/order_grocery.jpg";

import { Box, Container, Grid } from "@mui/material";
import DashboardImagePage from '../../common/dashboard-image-page/DashboardImagePage'
import { Link } from "react-router-dom";
import FoodCardItem from './FoodCard'

export default function FoodItemsList() {
    return (
        <div className="App">
            <ResponsiveAppBar></ResponsiveAppBar>
            {/* <DashboardImagePage></DashboardImagePage> */}
            <Container mt={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <FoodCardItem></FoodCardItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FoodCardItem imageSrc={orderGroceryImage} title={"Order Grocery"}></FoodCardItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FoodCardItem imageSrc={orderFoodImage} title={"Taste the spicy"}></FoodCardItem>
                    </Grid>
                    {/* {components.map((Component, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            {Component}
                        </Grid>
                    ))} */}
                </Grid>
            </Container>


            {/* <MultiActionAreaCard></MultiActionAreaCard>
            <MultiActionAreaCard></MultiActionAreaCard>
            <MultiActionAreaCard></MultiActionAreaCard>
            <MultiActionAreaCard></MultiActionAreaCard> */}

            {/* 
            <YoutubeEmbed embedId="rokGy0huYEA" />

            </MultiActionAreaCard> */}
            {/* <YoutubeEmbed embedId="rokGy0huYEA" /> */}
        </div>
    );
}
