import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';
import { purple } from '@mui/material/colors';


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#f2740d',
    '&:hover': {
        backgroundColor: purple[700],
    },
}));

export default function ButtonSizes() {
    return (
        <Box sx={{ '& button': { m: 1 } }}>
            <div>
                <ColorButton variant="contained">Send Otp</ColorButton>
            </div>
        </Box>
    );
}