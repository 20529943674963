import React, { useEffect } from "react";
import "./style.css";
import Signup from "../auth/SignupPage";
import ResponsiveAppBar from "../common/Appbar";
// import YoutubeEmbed from "./../common/youtube/Youtube";
import MultiActionAreaCard from "./YoutubeSection";
import RecipeReviewCard from "../common/Card";
import orderFoodImage from "./../../static/images/order_food.jfif";
import orderGroceryImage from "./../../static/images/order_grocery.jpg";
import orderElectronicImage from "./../../static/images/order_electronic.webp";

import { Box, Container, Grid } from "@mui/material";
import DashboardImagePage from '../common/dashboard-image-page/DashboardImagePage'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../module/foodItemSlice";

export default function Dashboard() {
    const dispatch = useDispatch();
    const { items, loading, error } = useSelector((state) => state.data);

    useEffect(() => {
        dispatch(fetchData());
    }, [dispatch]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }


    return (
        <div className="App">
            <ResponsiveAppBar></ResponsiveAppBar>
            <Container></Container>
            {/* <DashboardImagePage></DashboardImagePage> */}
            <Container mt={5}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="/food-items" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <RecipeReviewCard imageSrc={orderFoodImage} title={"Try Food"}></RecipeReviewCard>
                        </Link>

                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="/grocery-item" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <RecipeReviewCard imageSrc={orderGroceryImage} title={"Order Grocery"}></RecipeReviewCard>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="/drink-items" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <RecipeReviewCard imageSrc={orderElectronicImage} title={"Digital Items"}></RecipeReviewCard>
                        </Link>
                    </Grid>

                    {/* {components.map((Component, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            {Component}
                        </Grid>
                    ))} */}
                </Grid>
            </Container>


            {/* <MultiActionAreaCard></MultiActionAreaCard>
            <MultiActionAreaCard></MultiActionAreaCard>
            <MultiActionAreaCard></MultiActionAreaCard>
            <MultiActionAreaCard></MultiActionAreaCard> */}

            {/* 
            <YoutubeEmbed embedId="rokGy0huYEA" />

            </MultiActionAreaCard> */}
            {/* <YoutubeEmbed embedId="rokGy0huYEA" /> */}
        </div>
    );
}
