import React from "react";
// import YoutubeEmbed from "./../common/youtube/Youtube";
// import MultiActionAreaCard from "./YoutubeSection";

export default function ContactPage() {
    return (
        <div className="App">
            Contact Page
            {/* <MultiActionAreaCard>
            <YoutubeEmbed embedId="rokGy0huYEA" />

            </MultiActionAreaCard> */}
            {/* <YoutubeEmbed embedId="rokGy0huYEA" /> */}
        </div>
    );
}
