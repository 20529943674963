import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk for fetching data
export const fetchData = createAsyncThunk('data/fetchData', async () => {
    const response = await axios.get('https://stingray-app-3gcwh.ondigitalocean.app/api/v1/food-item', {
        headers: {
            Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGVOdW1iZXIiOiI5OTk5OTk5OTk5IiwidXNlcklkIjoiNjY2ODAzZDZmZGUwMjY0OTBjOTQ3MmUwIiwiaWF0IjoxNzIzNDA4MjcyLCJleHAiOjE4NTMwMDgyNzJ9.Kc0qkSRHAAYNBBvu3JcfhkdywAOzrLR5QNGrH163q30`,
        },
    });
    return response.data;
});

const dataSlice = createSlice({
    name: 'data',
    initialState: {
        items: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.items = action.payload;
                state.loading = false;
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default dataSlice.reducer;
